import { parseDatetimeForView } from './parseDatetimeForView';

export function gridDatetimeFilterComparator(filterLocalDateAtMidnight, cellValue) {
  const cellDatetime = parseDatetimeForView(cellValue);

  if (!cellDatetime) {
    return 0;
  }

  const filterDate = new Date(
    filterLocalDateAtMidnight.getFullYear(),
    filterLocalDateAtMidnight.getMonth(),
    filterLocalDateAtMidnight.getDate()
  );

  const cellDate = new Date(cellDatetime.getFullYear(), cellDatetime.getMonth(), cellDatetime.getDate());

  if (cellDate < filterDate) return -1;
  if (cellDate > filterDate) return 1;

  return 0;
}
