import React from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 7,
    minWidth: 510,
    maxWidth: 510,
    '& .MuiOutlinedInput-input': {
      paddingTop: 10,
      paddingBottom: 11,
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 11px) scale(1)',
      pointerEvents: 'none',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

let flag = false;
let flagOriginItems = false;
let originItems = [];
let start = true;
let selectedAllItemStorage = [];
let countCheckAllItem = 0;
let flagAll = false;
const nameAll = 'ALL';

const SearchMultiField = (props) => {
  const classes = useStyles();
  let { items } = props;
  const { itemsStorage, onHandleChangeItem, itemName } = props;
  const [selectedItem, setSelectedItem] = React.useState([]);

  const deepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
  };

  const selectedAllItems = (value) => {
    return value.map((x) => x.name);
  };

  const compare = (a, b) => {
    return a === b;
  };

  const setSelectAllDelerships = (value) => {
    setSelectedItem(selectedAllItems(value));
  };

  const removeDupes = (value) => {
    const result = {};
    let valueLength = value.length;
    while (valueLength--) {
      if (Object.prototype.hasOwnProperty.call(result, value[valueLength])) {
        result[value[valueLength]]++;
        value.splice(valueLength, 1);
      } else {
        result[value[valueLength]] = 1;
      }
    }
    const newResult = [];
    Object.keys(result).forEach((only) => {
      if (result[only] < 2) {
        newResult.push(only);
      }
    });
    newResult.forEach((res) => {
      if (res === undefined) {
        newResult.splice(res);
      }
    });

    return newResult;
  };

  const resolveArrays = (key, valueFirst, valueSecond) => {
    let value;
    switch (key) {
      case 0:
        valueFirst.forEach((first) => {
          valueSecond.target.value.push(first);
        });
        valueSecond.target.value = removeDupes(valueSecond.target.value);
        value = valueSecond;
        break;
      case 1:
        valueFirst.forEach((first) => {
          first.selected = true;
        });
        value = valueFirst;
        break;
      case 2:
        valueFirst.forEach((first) => {
          valueSecond.forEach((second) => {
            if (compare(first.name, second)) {
              first.selected = false;
            }
          });
        });
        value = valueFirst;
        break;
      case 3:
        valueFirst.forEach((first) => {
          if (first.selected) {
            valueSecond.push(first);
          }
        });
        if (valueSecond.findIndex((valueItem) => valueItem.name === nameAll) === 0) {
          valueSecond.splice(0, 1);
        }
        value = valueSecond;
        break;
      case 4:
        valueFirst.forEach((first) => {
          first.selected = false;
        });
        value = valueFirst;
        break;
      default:
        break;
    }
    return value;
  };

  if (start && items.length > 0) {
    selectedAllItemStorage = selectedAllItems(itemsStorage);
    setSelectedItem(selectedAllItemStorage);
    start = false;
  }

  const countCheckItem = (checkAll) => {
    if (checkAll === nameAll) {
      countCheckAllItem += 1;
    } else {
      countCheckAllItem = 0;
    }
  };

  const decideSetSelectedItem = (event) => {
    if (flag) {
      setSelectedItem([]);
    } else {
      setSelectedItem(event.target.value);
    }
  };

  const itemAllDistinctSelected = (collection) => {
    return !collection[0].selected;
  };

  const compareItems = (itemsToFound, event) => {
    itemsToFound.forEach((item) => {
      if (event.target.value.find((value) => value === item.name)) {
        if (item.name === nameAll && itemsToFound.length > event.target.value.length) {
          item.selected = false;
          flagAll = true;
        } else {
          item.selected = true;
          setSelectedItem(event.target.value);
        }
      } else {
        item.selected = false;
      }
    });
    return itemsToFound;
  };

  const selectItem = (event) => {
    let selectAll = false;
    let checkAll = event.target.value.find((x) => x === nameAll);
    if (checkAll === undefined && event.target.value.length + 1 === items.length) {
      if (itemAllDistinctSelected(items)) {
        checkAll = nameAll;
        selectAll = true;
        event.target.value.push(nameAll);
      }
    }
    countCheckItem(checkAll);

    if (!flagOriginItems) {
      originItems = deepCopy(items);
      flagOriginItems = true;
      event = resolveArrays(0, selectedAllItemStorage, event);
      const found = event.target.value.filter(
        (value) => value === selectedAllItemStorage.find((itemStorage) => itemStorage)
      );
      if (found && found.length > 1) {
        items = resolveArrays(2, items, found);
        setSelectedItem([]);
        event.target.value = [];
      }
    }

    if (checkAll) {
      flag = true;
      if (countCheckAllItem > 1) {
        items = compareItems(items, event);
        if (flagAll) {
          const index = event.target.value.findIndex((valueItem) => valueItem === nameAll);
          if (index >= 0 && itemAllDistinctSelected(items)) {
            event.target.value.splice(index, 1);
            setSelectedItem(event.target.value);
          }
        }
      } else {
        items = resolveArrays(1, items);
        setSelectAllDelerships(items);
      }
    } else {
      if (countCheckAllItem === 0 && items[0].selected) {
        items = resolveArrays(4, items);
        setSelectedItem([]);
      } else {
        items = deepCopy(originItems);
        items = resolveArrays(2, items, selectedAllItemStorage);

        if (event.target.value.length + 1 !== originItems.length) {
          items.forEach((item) => {
            event.target.value.forEach((value) => {
              if (compare(value, nameAll)) {
                selectAll = true;
              }
              if (compare(item.name, value)) {
                if (!item.selected) {
                  item.selected = !item.selected;
                }
              }
            });
          });
          flag = false;
        }
      }

      if (selectAll) {
        flag = true;
        setSelectAllDelerships(items);
      } else {
        decideSetSelectedItem(event);
        flag = false;
      }
    }
    const itemsToSend = resolveArrays(3, items, []);
    onHandleChangeItem(items, itemsToSend);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">Select {itemName}</InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={selectedItem}
        onChange={selectItem}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
      >
        {items.map((ds) => (
          <MenuItem key={ds.id} value={ds.name}>
            <Checkbox checked={ds.selected} />
            <ListItemText primary={ds.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { SearchMultiField };
