export function gridBadgeComparator(valueA, valueB) {
  let a = null;
  let b = null;

  if (valueA && valueA.count) a = Number(valueA.count);
  if (valueB && valueB.count) b = Number(valueB.count);

  if (a === null || b === null) return 0;

  if (a === null) return -1;
  if (b === null) return 1;

  return a - b;
}
