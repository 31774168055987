import React, { PureComponent } from 'react';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Typography,
  withTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DriveEta as CarIcon, AttachMoney as MoneyIcon } from '@material-ui/icons';

const styles = (theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 'auto 8px auto 0',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(16),
  },
  titleContent: { display: 'inline-flex' },
  chip: {
    margin: '-1px 8px auto 0',
    color: theme.palette.primary.contrastText,
  },
});

class HouseholdAndGarageOrganismComponent extends PureComponent {
  renderGarageItem = (item, index) => {
    const description = [item.Make, item.Model, item.Year].filter((x) => !!x).join(' ');

    return (
      <ListItem key={index}>
        <ListItemIcon>
          <CarIcon />
        </ListItemIcon>
        <ListItemText primary={description} />
      </ListItem>
    );
  };

  render() {
    const { classes, data } = this.props;

    let garageItems = data.Garage.map((x, index) => {
      if (!x || !x.Make) {
        return null;
      }

      return this.renderGarageItem(x, index);
    });

    let householdIncomeText = 'No household income data available';
    if (!!data.HouseholdIncome && !!data.HouseholdIncome.Description) {
      householdIncomeText = data.HouseholdIncome.Description;
    }

    const garageItemsCount = garageItems.length;
    if (!garageItemsCount)
      garageItems = [
        <ListItem>
          <ListItemIcon>
            <CarIcon />
          </ListItemIcon>
          <ListItemText primary="No available information on current garage." />
        </ListItem>,
      ];

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="household-garage-content"
          id="household-garage-header"
        >
          <div className={classes.titleContent}>
            <Typography variant="h6" className={classes.heading}>
              Household & Garage
            </Typography>
            <Chip label={data.Garage.length} color="primary" size="small" variant="outlined" className={classes.chip} />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.paper}>
            <div style={{ height: 250, width: '100%' }}>
              {
                <>
                  <Typography className={classes.subheading}>Household Income</Typography>
                  <List dense disablePadding>
                    <ListItem>
                      <ListItemIcon>
                        <MoneyIcon />
                      </ListItemIcon>
                      <ListItemText primary={householdIncomeText} />
                    </ListItem>
                  </List>
                </>
              }
              {!!garageItems && !!garageItems.length && (
                <>
                  <Typography className={classes.subheading}>Vehicles in Garage</Typography>
                  <List dense disablePadding>
                    {garageItems}
                  </List>
                </>
              )}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export const HouseholdAndGarageOrganism = withStyles(styles)(withTheme(HouseholdAndGarageOrganismComponent));
