import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { NamesCellRenderer, TextCellRenderer } from '../atoms';
import { GlobalState } from '../../GlobalState';
import { displayDatetimeFormatter } from '../../lib/operators/displayDatetimeFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { gridTextToNumberComparator } from '../../lib/operators/gridTextToNumberComparator';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
  },
});

class VehicleOwnerGridTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.asaPermissions = GlobalState.PermissionsMediator.getAsaPermissions();
    this.setupStatics();
  }

  componentDidMount() {
    this.dataGrid.api.sizeColumnsToFit();

    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  onRowSelection = (evt) => {
    const selected = evt.api.getSelectedRows();
    const leadId = selected[0].lead_identity_id;
    this.props.onLeadSelection(leadId);
  };

  setupStatics = () => {
    this.frameworkComponents = {
      namesCellRenderer: NamesCellRenderer,
      textRenderer: TextCellRenderer,
    };

    this.onFirstDataRendered = (params) => {
      params.api.sizeColumnsToFit();
    };

    this.columnDefinitions = [
      {
        headerName: 'ID',
        field: 'lead_identity_id',
        sortable: true,
        width: 60,
        cellRenderer: 'textRenderer',
        headerTooltip: 'ID',
        resizable: true,
      },
      {
        headerName: 'Lead',
        field: 'full_name',
        cellRenderer: 'textRenderer',
        filter: true,
        sortable: true,
        width: 170,
        headerTooltip: 'Lead',
        resizable: true,
      },
      {
        headerName: 'City',
        field: 'city',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 100,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.city);
        },
        headerTooltip: 'City',
        resizable: true,
      },
      {
        headerName: 'State',
        field: 'state',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 80,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.state);
        },
        headerTooltip: 'State',
        resizable: true,
      },
      {
        headerName: 'ASA',
        field: 'asa',
        sortable: true,
        width: 75,
        filter: 'agTextColumnFilter',
        cellRenderer: 'textRenderer',
        filterParams: { comparator: gridTextToNumberComparator },
        cellStyle: (params) => {
          if (this.asaPermissions.includes(params.value)) {
            return { color: this.props.theme.hendrick.asaIndicators.currentAsaColor };
          }
          return null;
        },
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.asa);
        },
        headerTooltip: 'ASA',
        resizable: true,
      },
      {
        headerName: 'Source',
        field: 'source',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        valueGetter: (params) => {
          return params.data.source === 'contract_header' ? 'contracts' : params.data.source;
        },
        width: 85,
        headerTooltip: 'Source',
        resizable: true,
      },
      {
        headerName: 'Deal date',
        field: 'deal_date',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => displayDatetimeFormatter(value),
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        width: 107,
        comparator: gridDatetimeSortComparator,
        headerTooltip: 'Deal date',
        resizable: true,
      },
      {
        headerName: 'VIN',
        field: 'vehicle.VIN',
        sortable: true,
        cellRenderer: 'textRenderer',
        filter: 'agTextColumnFilter',
        filterParams: { comparator: gridTextToNumberComparator },
        width: 80,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.vehicle.VIN);
        },
        headerTooltip: 'VIN',
        resizable: true,
      },
      {
        headerName: 'Make',
        field: 'vehicle.Make',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 80,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.vehicle.Make);
        },
        headerTooltip: 'Make',
        resizable: true,
      },
      {
        headerName: 'Model',
        field: 'vehicle.Model',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 90,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.vehicle.Model);
        },
        headerTooltip: 'Model',
        resizable: true,
      },
      {
        headerName: 'Year',
        field: 'vehicle.Year',
        sortable: true,
        cellRenderer: 'textRenderer',
        filter: 'agTextColumnFilter',
        filterParams: { comparator: gridTextToNumberComparator },
        width: 75,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.vehicle.Year);
        },
        headerTooltip: 'Year',
        resizable: true,
      },
    ];
  };

  render() {
    const { classes, data } = this.props;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.paper}>
            <div style={{ height: 520, width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              {data && (
                <AgGridReact
                  ref={(c) => (this.dataGrid = c)}
                  paginationAutoPageSize
                  rowData={data}
                  columnDefs={this.columnDefinitions}
                  content={{ componentParent: this }}
                  rowSelection="single"
                  frameworkComponents={this.frameworkComponents}
                  pagination
                  rowHeight={38}
                  headerHeight={40}
                  onFirstDataRendered={this.onFirstDataRendered}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const VehicleOwnerGridTemplate = withStyles(styles)(withTheme(VehicleOwnerGridTemplateComponent));
