import * as React from 'react';
import { withTheme } from '@material-ui/core';

function ProtectionProductBadgeOwnDealership(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 89.035 84.887" {...props}>
      <defs>
        <radialGradient
          id="protection_product_badge_own_dealership"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientTransform="matrix(-1 0 0 1 1 0)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor={props.theme.hendrick.ownDealershipBadgeColor.light} />
          <stop offset={1} stopColor={props.theme.hendrick.ownDealershipBadgeColor.base} />
        </radialGradient>
      </defs>
      <path
        d="M-629.6-529.218l-17.373 17.173v67.713h71.23l-.117-4.933-67.392-.318-.128-60.174 15.7-.114-.01-15.413 48.118-.018.112 26.158 3.6-.184v-29.866zm-.677 6.177l-.467 9.563-10.039.038zm25.694 6.141c-7.139 0-14.278.105-14.489.316-.412.412-.412 7.648 0 8.06s28.557.421 28.978 0 .412-7.648 0-8.06c-.215-.21-7.354-.316-14.494-.316zm-27.076 12.809l-.086 4.263h41.443l.113-4.263zm55.042 5.891c-4.1 5.742-12.8 8.958-18.173 3.311-.566.176-.675 4.886-.605 11.335.091 8.414 1.282 12.267 2.093 14.928 2.129 6.567 10.383 12.144 16.846 15.883 9.256-5.7 14.98-11.76 16.508-16.751 1.458-4.76 1.912-7.382 2-15.8.066-6.05-.31-9.713-.77-10.1-2.444 4.649-14.711 2.96-17.903-2.806zm-55.128 2.408v3.912h34.173v-3.912zm0 8.028v3.913h34.112v-3.913zm66.833 1.652l2.591 1.942-14.306 18.386-11.268-9.954 2.852-2.728 8.21 6.772zm-46.621 6.325v4.292l11.216-.108-.074-4.293zm-20.212.156v4.107h18.614v-4.107zm0 7.823v4.216h32.944v-4.216zm-1.9 10.675l-.207 5.7 16.909-.077.035-5.565z"
        transform="translate(646.977 529.218)"
        fill="url(#protection_product_badge_own_dealership)"
      />
    </svg>
  );
}

export const ProtectionProductBadgeOwn = withTheme(ProtectionProductBadgeOwnDealership);
