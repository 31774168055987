import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { BadgesCellRenderer, NamesCellRenderer, RankingNumberCellRenderer, TextCellRenderer } from '../atoms';
import { GlobalState } from '../../GlobalState';
import { displayDatetimeFormatter } from '../../lib/operators/displayDatetimeFormatter';
import { displayPercentFormatter } from '../../lib/operators/displayPercentFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { gridTextToNumberComparator } from '../../lib/operators/gridTextToNumberComparator';
import { gridBadgeComparator } from '../../lib/operators/gridBadgeComparator';
import { gridPercentComparator } from '../../lib/operators/gridPercentComparator';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';
import { HeaderGrid } from '../molecules';
import { API } from '../../lib/xhr';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { gridAutosizeHeaders } from '../../lib/operators/gridAutosizeHeaders';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: 3,
  },
  rightAlign: {
    textAlign: 'right',
  },
  centerAlign: {
    textAlign: 'center',
  },
  titleTypography: {
    fontFamily: 'roboto, sans-serif',
    textAlign: 'center',
    fontSize: '0.7rem',
    marginLeft: 20,
    marginTop: 14,
  },
  iconButton: {
    marginRight: -10,
    marginTop: 4,
  },
  iconSave: {
    marginRight: 1,
    marginTop: 4,
  },
});

class RankingViewTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.notifier = new SnackbarVisitor(props);
    this.api = new API(this.notifier);

    this.columnDefinitions = [];
    this.asaPermissions = GlobalState.PermissionsMediator.getAsaPermissions();
    this.userUploadedDate = GlobalState.PermissionsMediator.getUserUploadedDate();
    this.existUserPreferenceInLocalStorage = this.existGridPreferencesInStorage();
    this.pageSizeOptions = [
      { label: '10', value: '10' },
      { label: '25', value: '25' },
      { label: '50', value: '50' },
    ];
    this.pageSize = false;
    this.changePageSize = false;
    this.lastTitle = '';
    const statePageSize = this.choicePageSizeOption();
    this.state = {
      pageSize: statePageSize,
      isEnabledRestoreIcon: !!(statePageSize !== '10' || this.existUserPreferenceInLocalStorage),
      isEnabledSaveIcon: false,
    };

    this.runSetupStaticsWithLocalStorage();
  }

  existGridPreferencesInStorage() {
    return this.getPreferencesByLocalStorage() && this.getPreferencesByLocalStorage().GridPreferences.length > 0;
  }

  componentDidMount() {
    this.existUserPreferenceInLocalStorage =
      this.getPreferencesByLocalStorage() && this.existGridPreferencesInStorage();
    this.dataGrid.api.sizeColumnsToFit();
    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateTitle !== this.props.dateTitle) this.changePageSize = false;
    this.existUserPreferenceInLocalStorage = this.existGridPreferencesInStorage();

    if (!this.changePageSize) {
      this.runSetupStaticsWithLocalStorage();
    }
    this.setPageSize(prevProps);
    this.refreshGrid();
  }

  setPageSize(prevProps) {
    if (prevProps.dateTitle !== this.props.dateTitle) this.pageSize = false;
    const statePageSize = this.choicePageSizeOption();
    this.changeState(statePageSize);
  }

  changeState(statePageSize) {
    this.setState({ pageSize: statePageSize });
  }

  getPreferencesByLocalStorage() {
    const getUserPreferences = GlobalState.PermissionsMediator.getUserPreferences();
    const userPreferencesPage = getUserPreferences.pages.filter((x) => x.PageName === this.getPageName());
    return userPreferencesPage[0];
  }

  runSetupStaticsWithLocalStorage() {
    const userPeferences = this.getPreferencesByLocalStorage();
    const preferencesLocalStorage = userPeferences ? userPeferences.GridPreferences : [];
    this.setupStatics(preferencesLocalStorage);
  }

  refreshGrid() {
    this.dataGrid.api.setColumnDefs([]);
    this.dataGrid.api.setColumnDefs(this.columnDefinitions);
    this.dataGrid.api.setRowData([]);
    this.dataGrid.api.setRowData(this.props.data);
    this.dataGrid.api.sizeColumnsToFit();
    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  choicePageSizeOption() {
    const userPeferences = this.getPreferencesByLocalStorage();
    const preferencesLocalStorage = this.pageSize ? this.pageSize : userPeferences.PageSize;
    const finalSize = this.pageSizeOptions.filter((x) => x.value === preferencesLocalStorage)[0].value;
    return finalSize;
  }

  onRowSelection = (evt) => {
    const selected = evt.api.getSelectedRows();
    const leadId = selected[0].lead_identity_id;
    this.props.onLeadSelection(leadId);
  };

  updateRunOrderNo = () => {
    this.columnDefinitions.forEach((columnPreference) => {
      const index = this.columnDefinitions.findIndex((data1) => columnPreference === data1);
      columnPreference.index = index;
    });
  };

  moveByIndex = (fromIndex, toIndex) => {
    const element = this.columnDefinitions[fromIndex];
    this.columnDefinitions.splice(fromIndex, 1);
    this.columnDefinitions.splice(toIndex, 0, element);
  };

  setupStatics = (gridPreferences) => {
    this.frameworkComponents = {
      badgesRenderer: BadgesCellRenderer,
      namesCellRenderer: NamesCellRenderer,
      rankRenderer: RankingNumberCellRenderer,
      textRenderer: TextCellRenderer,
    };

    this.onFirstDataRendered = (params) => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 50);
    };

    this.getRowStyle = (params) => {
      if (
        displayDatetimeFormatter(params.data.last_activity_datetime) > displayDatetimeFormatter(this.userUploadedDate)
      ) {
        return { color: 'YELLOW' };
      }
      return false;
    };

    this.onColumnMoved = (params) => {
      const column = this.columnDefinitions.find((x) => x.field === params.column.colDef.field);
      this.moveByIndex(column.index, params.toIndex);
      this.updateRunOrderNo();
      this.setState({ isEnabledRestoreIcon: true, isEnabledSaveIcon: true });
    };

    this.onColumnResized = (params) => {
      gridAutosizeHeaders(params);
      const index = this.columnDefinitions.findIndex((x) => x.field === params.column.colDef.field);
      this.columnDefinitions[index].width = params.column.actualWidth;
      this.setState({ isEnabledRestoreIcon: true, isEnabledSaveIcon: true });
    };

    this.findColumnByProperty = (field) => {
      return gridPreferences && gridPreferences.find((property) => property && property.ColumnName === field);
    };

    const column = (headerName, field) => ({ headerName, field, headerTooltip: headerName });

    const columnDefinitions = [
      {
        ...column('#', 'ranking_position'),
        cellRenderer: 'rankRenderer',
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('ranking_position').ColumnSize : 0,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('ranking_position').ColumnOrder : 0,
      },
      {
        ...column('Lead', 'full_name'),
        cellRenderer: 'textRenderer',
        filter: true,
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('full_name').ColumnSize : 155,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('full_name').ColumnOrder : 1,
      },
      {
        ...column('Match Probability', 'match_probability'),
        filter: true,
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('match_probability').ColumnSize : 92,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('match_probability').ColumnOrder : 2,
        colId: 'matchProbability',
        comparator: gridPercentComparator,
        valueFormatter: ({ value }) => displayPercentFormatter(value),
        cellStyle: (params) => {
          let color;

          switch (true) {
            case params.value >= 80:
              color = this.props.theme.hendrick.otherDealershipBadgeColor.light;
              break;
            case params.value >= 60 && params.value < 80:
              color = this.props.theme.hendrick.yellowDealershipBadgeColor.light;
              break;
            case params.value < 60:
              color = this.props.theme.hendrick.greyedDealershipBadgeColor.light;
              break;
            default:
              color = this.props.theme.hendrick.greyedDealershipBadgeColor.light;
          }
          return {
            justifyContent: 'left',
            color,
          };
        },
      },
      {
        ...column('Vehicles', 'sales_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'vehicle',
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('sales_badge').ColumnSize : 90,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('sales_badge').ColumnOrder : 3,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Services', 'services_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'serviceLane',
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('services_badge').ColumnSize : 84,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('services_badge').ColumnOrder : 4,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Protection products', 'warranties_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'protectionProduct',
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('warranties_badge').ColumnSize : 102,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('warranties_badge').ColumnOrder : 5,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Unused redemptions', 'unredeemed_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'unusedRedemptions',
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('unredeemed_badge').ColumnSize : 90,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('unredeemed_badge').ColumnOrder : 6,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Age Inventory', 'inventory_vehicle'),
        cellRenderer: 'badgesRenderer',
        colId: 'ageInventory',
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('inventory_vehicle').ColumnSize : 90,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('inventory_vehicle').ColumnOrder : 7,
        comparator: gridBadgeComparator,
      },
      {
        ...column('ASA', 'asa'),
        sortable: true,
        width: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('asa').ColumnSize : 75,
        index: this.existUserPreferenceInLocalStorage ? this.findColumnByProperty('asa').ColumnOrder : 8,
        filter: 'agTextColumnFilter',
        cellRenderer: 'textRenderer',
        filterParams: { comparator: gridTextToNumberComparator },
        cellStyle: (params) => {
          if (this.asaPermissions.includes(params.value)) {
            return { color: this.props.theme.hendrick.asaIndicators.currentAsaColor };
          }
          return null;
        },
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.asa);
        },
      },
      {
        ...column('Store Name', 'inventory_vehicle.DealerId'),
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: this.existUserPreferenceInLocalStorage
          ? this.findColumnByProperty('inventory_vehicle.DealerId').ColumnSize
          : 90,
        index: this.existUserPreferenceInLocalStorage
          ? this.findColumnByProperty('inventory_vehicle.DealerId').ColumnOrder
          : 9,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.inventory_vehicle.DealerId);
        },
      },
      {
        ...column('Date lead', 'last_activity_datetime'),
        sortable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => displayDatetimeFormatter(value),
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        width: this.existUserPreferenceInLocalStorage
          ? this.findColumnByProperty('last_activity_datetime').ColumnSize
          : 103,
        index: this.existUserPreferenceInLocalStorage
          ? this.findColumnByProperty('last_activity_datetime').ColumnOrder
          : 10,
        comparator: gridDatetimeSortComparator,
      },
    ];
    this.defaultColDef = {
      resizable: true,
    };

    columnDefinitions.sort((a, b) => {
      return a.index - b.index;
    });

    this.columnDefinitions.splice(0, this.columnDefinitions.length);

    columnDefinitions.forEach((columnDefinition) => {
      this.columnDefinitions.push(columnDefinition);
    });
  };

  onInputChange = (event) => {
    this.changePageSize = true;
    this.pageSize = event.target.value;
    this.setState({ [event.target.name]: event.target.value, isEnabledRestoreIcon: true, isEnabledSaveIcon: true });
    this.dataGrid.api.paginationSetPageSize(Number(event.target.value));
  };

  getPageName() {
    return `leads of the ${this.props.dateTitle}`;
  }

  restorePageSettings = () => {
    const body = { PageName: this.getPageName(), ComponentName: 'ranking_by_date_grid' };
    this.api.request
      .post(`userpreferences/restoreGridSettings`, body)
      .success((res) => {
        if (res.body) {
          this.updateUserPrefrenceInLocalStorage();
          this.setState({
            pageSize: this.pageSizeOptions[0].value,
            isEnabledRestoreIcon: false,
            isEnabledSaveIcon: false,
          });
        }
      })
      .go();
  };

  updateUserPrefrenceInLocalStorage() {
    const getUserPreferences = GlobalState.PermissionsMediator.getUserPreferences();
    const index = getUserPreferences.pages.findIndex((x) => x.PageName === this.getPageName());
    getUserPreferences.pages[index].PageSize = this.pageSizeOptions[0].value;
    getUserPreferences.pages[index].GridPreferences = [];
    GlobalState.PermissionsMediator.setUserPreferences(getUserPreferences);
  }

  savePageSettings = () => {
    const pageName = this.getPageName();
    const body = {
      PageName: pageName,
      PageSize: this.state.pageSize,
    };

    body.GridPreferences = [];
    this.columnDefinitions.forEach((element) => {
      body.GridPreferences.push({
        ComponentName: `ranking_by_date_grid`,
        ColumnName: element.field,
        ColumnSize: element.width,
        ColumnOrder: element.index,
      });
    });

    this.api.request
      .post(`userpreferences/saveGridPreferences`, body)
      .success((res) => {
        const page = res.body.Pages.filter((x) => x.PageName === pageName);
        const getUserPreferences = GlobalState.PermissionsMediator.getUserPreferences();
        const userPreferencesPage = getUserPreferences.pages.findIndex((x) => x.PageName === pageName);
        getUserPreferences.pages[userPreferencesPage].PageSize = page[0].PageSize;
        getUserPreferences.pages[userPreferencesPage].GridPreferences = body.GridPreferences;

        GlobalState.PermissionsMediator.setUserPreferences(getUserPreferences);

        this.setState({
          pageSize: page[0].PageSize,
          isEnabledRestoreIcon: true,
          isEnabledSaveIcon: false,
        });
      })
      .go();
  };

  render() {
    const { classes, data } = this.props;
    const { pageSize, isEnabledRestoreIcon, isEnabledSaveIcon } = this.state;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.paper}>
            <div style={{ height: '100%', width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              <HeaderGrid
                text="Hendrick Relationships"
                pageSize={pageSize}
                onInputChange={this.onInputChange}
                pageSizeOptions={this.pageSizeOptions}
                restoreGridSettings={this.restorePageSettings}
                saveGridSettings={this.savePageSettings}
                isEnabledRestoreIcon={isEnabledRestoreIcon}
                isEnabledSaveIcon={isEnabledSaveIcon}
              />
              {data && (
                <AgGridReact
                  ref={(c) => (this.dataGrid = c)}
                  paginationPageSize={pageSize}
                  rowData={data}
                  columnDefs={this.columnDefinitions}
                  content={{ componentParent: this }}
                  rowSelection="single"
                  frameworkComponents={this.frameworkComponents}
                  pagination
                  rowHeight={38}
                  groupHeaderHeight={35}
                  onFirstDataRendered={this.onFirstDataRendered}
                  suppressHorizontalScroll
                  onColumnMoved={this.onColumnMoved}
                  onColumnResized={this.onColumnResized}
                  getRowStyle={this.getRowStyle}
                  onGridReady={gridAutosizeHeaders}
                  defaultColDef={this.defaultColDef}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const RankingViewTemplate = withStyles(styles)(withTheme(RankingViewTemplateComponent));
