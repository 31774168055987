import React, { PureComponent } from 'react';
import { withSnackbar } from 'notistack';
import { SignInTemplate } from '../template';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { API } from '../../lib/xhr';
import { GlobalState } from '../../GlobalState';
import { md5 } from '../../lib/crypto/md5';
import { DefaultUserPreferences } from '../../lib/preferences/DefaultUserPreferences';

class SignInPageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.notifier = new SnackbarVisitor(this.props);
    this.api = new API(this.notifier);

    this.state = {
      username: '',
      password: '',
    };
  }

  onSignInConfirm = () => {
    const { username, password } = this.state;

    if (!username) {
      this.notifier.error('Type in your e-mail.');
      return;
    }

    if (!password) {
      this.notifier.error('Type in your password.');
      return;
    }

    const body = { username, password: md5(password) };

    this.api.request
      .post('authentication', body)
      .preventDefaultSuccess()
      .success((res) => {
        const { permissions, token, dealerships, userUploadedDate, preferences } = res.body;

        GlobalState.History = this.props.history;
        document.cookie = `auth=${token}`;

        const asaPermissions = [];
        const userPreferences = DefaultUserPreferences;

        if (preferences.themeValue) {
          userPreferences.theme_value = preferences.themeValue;
        }

        if (preferences.Pages.length > 0) {
          userPreferences.pages.forEach((preference, index) => {
            const defaultPreferenceIndex = preferences.Pages.findIndex((x) => x.PageName === preference.PageName);
            if (defaultPreferenceIndex >= 0) userPreferences.pages[index] = preferences.Pages[defaultPreferenceIndex];
          });
        }

        if (dealerships.length)
          dealerships.forEach((d) => {
            if (d.asa && d.asa.length) asaPermissions.push(...d.asa);
          });

        GlobalState.PermissionsMediator.refreshData(
          permissions,
          dealerships,
          asaPermissions,
          userUploadedDate,
          userPreferences
        );

        this.notifier.success('Welcome to Traffic Buddy');
        this.redirectAfterSignIn();

        GlobalState.AppComponent.startSessionTimer();
      })
      .go();
  };

  redirectAfterSignIn = () => {
    const mediator = GlobalState.PermissionsMediator;

    if (mediator.has('VIEW_RANKING')) {
      this.props.history.push('/ranking/day');
      return;
    }

    this.props.history.push('./search');
  };

  onInputChange = (event) => this.setState({ [event.target.name]: event.target.value });

  render() {
    const { username, password } = this.state;

    return (
      <SignInTemplate
        username={username}
        password={password}
        onSignInConfirm={this.onSignInConfirm}
        onInputChange={this.onInputChange}
      />
    );
  }
}

export const SignInPage = withSnackbar(SignInPageComponent);
