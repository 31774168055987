import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: { display: 'inline-flex' },
  chip: {
    margin: 'auto 6px auto 0',
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black,
    border: '1px solid #a8a6a6',
  },
}));

function ChipBase(props, component) {
  const classes = useStyles();
  const { count, badgeStyle } = props;
  return (
    <div className={classes.content}>
      <Chip label={count} variant="outlined" size="small" className={classes.chip} />
      <div style={badgeStyle}>{component}</div>
    </div>
  );
}

export { ChipBase };
