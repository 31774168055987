import React from 'react';
import { Box } from '@material-ui/core';

// const styles = {
//   chip: {
//     fontSize: '10px',
//     height: '15px',
//   },
// };

const NamesCellRenderer = (props) => {
  return <Box>{props.value}</Box>;
};

export { NamesCellRenderer };
