import React, { PureComponent } from 'react';
import { withSnackbar } from 'notistack';
import { Poll as PollIcon } from '@material-ui/icons';
import { PageLayout, PropensityRankingTemplate } from '../template';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { API } from '../../lib/xhr';
import { PageTitle } from '../molecules';

class RankingViewPageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.notifier = new SnackbarVisitor(this.props);
    this.api = new API(this.notifier);

    this.state = {
      rankingData: [],
    };
  }

  componentDidMount() {
    this.performRefresh();
  }

  performRefresh = () => {
    this.api.request
      .get('ranking')
      .success((res) => this.setState({ rankingData: res.body }))
      .go();
  };

  onGoToLead = (leadId) => {
    this.props.history.push(`/lead/${leadId}`);
  };

  render() {
    const { rankingData } = this.state;
    const dataIsReady = !!(rankingData && rankingData.length);

    return (
      <PageLayout>
        <PageTitle title="Propensity Ranking">
          <PollIcon fontSize="large" />
        </PageTitle>
        {dataIsReady && <PropensityRankingTemplate onLeadSelection={this.onGoToLead} data={rankingData} />}
      </PageLayout>
    );
  }
}
export const RankingViewPage = withSnackbar(RankingViewPageComponent);
