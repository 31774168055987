import * as React from 'react';
import { withTheme } from '@material-ui/core';

function ServiceLaneBadgeGreyedComponent(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 113.515 91.505" {...props}>
      <defs>
        <filter id="service_lane_badge_greyed" x={0} y={0} width={113.515} height={91.505} filterUnits="userSpaceOnUse">
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1.5} result="b" />
          <feFlood floodColor={props.theme.hendrick.greyedDealershipBadgeColor.shadow} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#service_lane_badge_greyed)">
        <path
          d="M85.663 60.436c-3.876-12.319-6.2-20.871-9.354-31.931-11.6-5.425-10.23-10.455-7.558-21.378l1.613-.407 3.608 11.533 6.915 2.394 4.105-5.435-4.276-10.985 1.512-.732c4.957 2 10.165 7.454 10.177 13.466.007 3.387-3.27 6.868-5.718 9.552 6.234 19.514 6.223 19.4 9.548 30.949 15.17 5.158 14.445 15.947 9.965 25.007l-1.52.451-3.509-12.853-7.537-1.492-4.515 5.128 4.071 11.566a7.7 7.7 0 01-1.8.73c-12.565-7.322-12.711-19.032-5.724-25.565zm-75.392 14.24c-2.076-.9-2.2-2.009-2.487-22.742-.179-12.759.4-14.241 6.971-17.7 2.129-1.123 2.128-1.121 1.066-1.748a1.942 1.942 0 00-1.889-.168c-6.353 1.755-12.824-5.073-7.4-7.808 3.627-1.829 10.085.964 10.085 4.361 0 .506 1.063 1.445 1.637 1.445a15.752 15.752 0 001.688-3.334c6.128-14.279 3.4-12.9 25.47-12.9 17.377 0 17.377 0 17.377 1.516a8.547 8.547 0 00.17 1.959c.154.4-1.393.446-16.16.476-20.175.041-18.086-.426-20.2 4.514a740.692 740.692 0 01-2.656 6.087 24.5 24.5 0 00-1.542 3.988c.069.115 11.25.21 24.845.21 24.718 0 24.718 0 25.205 2.029.386 1.607 3.26 12.017 3.94 14.271.14.464-1.089.49-23.084.49-25.105 0-24.3-.049-25.063 1.55-.495 1.043-.617 6.605-.171 7.779.766 2.016-1.373 1.864 26.26 1.864 24.738 0 24.738 0 23.438 1.446a22.232 22.232 0 00-2.213 3.008c-.913 1.562-.913 1.562-22.651 1.634s-21.738.072-21.739 2.518c0 2.849-.46 4.048-1.93 5.03-1.186.789-17.228.981-18.967.224zm11.931-22.392c5.764-2.981-2.444-12.1-8.486-9.426-4.983 2.2-.388 9.839 5.922 9.84a6.892 6.892 0 002.564-.415z"
          fill={props.theme.hendrick.greyedDealershipBadgeColor.base}
        />
      </g>
    </svg>
  );
}

export const ServiceLaneBadgeGreyed = withTheme(ServiceLaneBadgeGreyedComponent);
