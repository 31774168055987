import React, { PureComponent } from 'react';
import { Box } from '@material-ui/core';
import { VehicleChip, ServiceLaneChip, ProtectionProductChip, RedemptionsChip, AgeInventoryChip } from '../molecules';

const styles = {
  content: { display: 'inline' },
  badgeBase: {
    verticalAlign: 'middle',
    marginRight: '5px',
  },
};
class BadgesCellRenderer extends PureComponent {
  render() {
    return (
      <Box style={styles.content}>
        {this.props.column.colId === 'vehicle' && (
          <VehicleChip
            count={this.props.value.count}
            ownership={this.props.value.ownership}
            badgeStyle={{ height: 52, width: 52 }}
          />
        )}
        {this.props.column.colId === 'serviceLane' && (
          <ServiceLaneChip
            count={this.props.value.count}
            ownership={this.props.value.ownership}
            badgeStyle={{ height: 50, width: 38 }}
          />
        )}
        {this.props.column.colId === 'protectionProduct' && (
          <ProtectionProductChip
            count={this.props.value.count}
            ownership={this.props.value.ownership}
            badgeStyle={{ height: 40, width: 32 }}
          />
        )}
        {this.props.column.colId === 'unusedRedemptions' && (
          <RedemptionsChip
            count={this.props.value.count}
            ownership={this.props.value.ownership}
            badgeStyle={{ height: 40, width: 32 }}
          />
        )}
        {this.props.column.colId === 'ageInventory' && (
          <AgeInventoryChip
            dateInStock={this.props.value.DateInStock}
            type={this.props.value.Type}
            badgeStyle={{ height: 52, width: 52 }}
          />
        )}
      </Box>
    );
  }
}

export { BadgesCellRenderer };
