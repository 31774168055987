import React from 'react';
import {
  makeStyles,
  ListItemIcon,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  CardHeader,
  Divider,
  CardActions,
  Avatar,
} from '@material-ui/core';
import {
  Mail as MailIcon,
  PhoneAndroid as PhoneIcon,
  Home as AddressIcon,
  AssignmentInd as AssignmentIndIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { VehicleChip } from './VehicleChip';
import { ServiceLaneChip } from './ServiceLaneChip';
import { ProtectionProductChip } from './ProtectionProductChip';
import { RedemptionsChip } from './RedemptionsChip';
import { GlobalState } from '../../GlobalState';

const useStyles = makeStyles((theme) => ({
  nameTypography: {
    fontSize: '1.4993rem',
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.white,
    height: '5rem',
  },
  avatar: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    color: theme.palette.primary.contrastText,
  },
  icons: {
    fill: theme.palette.primary.light,
  },
  isinASA: {
    color: theme.hendrick.asaIndicators.currentAsaColor,
  },
  badgeButton: {
    cursor: 'pointer',
  },
  title: {
    fontSize: '1.2rem',
    fontFamily: 'BebasNeueFont',
    marginBottom: '12px',
    marginLeft: '17px',
  },
}));

export const LeadContactInfo = (props) => {
  const classes = useStyles();
  const { lead, badges, handleItemClick, eleadsFeedContact } = props;
  const asaPermissions = GlobalState.PermissionsMediator.getAsaPermissions();
  const leadInfo = 'Lead Info';
  const vipInfo = 'VIP Info';

  function formatPhone(phone) {
    return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
  }

  if (lead.phones && lead.phones.length) {
    lead.formattedPhones = lead.phones
      .filter(Boolean)
      .map((phone) => formatPhone(phone))
      .filter((phone) => phone.trim().length);
  }

  const isValidAddress = lead.full_address && lead.full_address.length > 10;

  if (isValidAddress) {
    lead.state = lead.full_address ? lead.full_address.substr(lead.full_address.length - 8, 2) : '';
    lead.zip = lead.full_address ? lead.full_address.slice(lead.full_address.length - 5) : '';
    lead.address = lead.full_address ? lead.full_address.substr(0, lead.full_address.length - 8) : '';
  } else {
    lead.full_address = '-';
  }

  return (
    <Card raised className={classes.content}>
      <CardHeader
        title={lead.full_name}
        subheader={`Lead Id:  ${lead.lead_identity_id}`}
        className={classes.header}
        titleTypographyProps={{ variant: 'h6' }}
        subheaderTypographyProps={{ variant: 'caption' }}
        avatar={
          <Avatar className={classes.avatar}>
            <AssignmentIndIcon />
          </Avatar>
        }
      />
      {eleadsFeedContact &&
        eleadsFeedContact.map((contactInfo) => (
          <CardContent>
            <List container spacing={2}>
              <Typography className={classes.title}>{leadInfo}</Typography>
              {contactInfo.FirstName && contactInfo.LastName && (
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon className={classes.icons} />
                  </ListItemIcon>
                  <Typography>
                    {' '}
                    {`${contactInfo.FirstName.toUpperCase()} ${contactInfo.LastName.toUpperCase()}`}
                  </Typography>
                </ListItem>
              )}
              {contactInfo.Address && (
                <ListItem>
                  <ListItemIcon>
                    <AddressIcon className={classes.icons} />
                  </ListItemIcon>
                  <Typography>
                    {contactInfo.Address.toUpperCase().replace(/ /g, '') !== ''
                      ? `${contactInfo.Address.toUpperCase()} ,`
                      : '-'}
                    <br />
                    {contactInfo.State.toUpperCase()}
                    <span className={classes.isinASA}> {contactInfo.Zip}</span>
                  </Typography>
                </ListItem>
              )}
              {contactInfo.Phones && (
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon className={classes.icons} />
                  </ListItemIcon>
                  <Typography>
                    {contactInfo.Phones.split(',')
                      .map((phone) => formatPhone(phone))
                      .join(',')}
                  </Typography>
                </ListItem>
              )}
              {contactInfo.Emails && (
                <ListItem>
                  <ListItemIcon>
                    <MailIcon className={classes.icons} />
                  </ListItemIcon>
                  <Typography>{contactInfo.Emails.toUpperCase()}</Typography>
                </ListItem>
              )}
            </List>
          </CardContent>
        ))}
      <Divider />
      <CardContent>
        <List container spacing={2}>
          <Typography className={classes.title}>{vipInfo}</Typography>
          <ListItem>
            <ListItemIcon>
              <AddressIcon className={classes.icons} />
            </ListItemIcon>
            {isValidAddress ? (
              <Typography>
                {lead.address}, <br /> {lead.state}
                {asaPermissions.includes(lead.zip) ? (
                  <span className={classes.isinASA}> {lead.zip}</span>
                ) : (
                  <span> {lead.zip}</span>
                )}
              </Typography>
            ) : (
              <Typography> {lead.full_address}</Typography>
            )}
          </ListItem>
          {lead.formattedPhones &&
            lead.formattedPhones.map((phone) => (
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon className={classes.icons} />
                </ListItemIcon>
                <Typography>{phone}</Typography>
              </ListItem>
            ))}

          {lead.emails &&
            lead.emails.map((email) => (
              <ListItem>
                <ListItemIcon>
                  <MailIcon className={classes.icons} />
                </ListItemIcon>
                <Typography>{email}</Typography>
              </ListItem>
            ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardAction}>
        <div onClick={handleItemClick('vehicleSalesPanel')} aria-hidden="true" className={classes.badgeButton}>
          <VehicleChip
            count={badges.Sales.count}
            ownership={badges.Sales.ownership}
            badgeStyle={{ height: 50, width: 55, marginRight: 16 }}
          />
        </div>
        <div onClick={handleItemClick('serviceLanePanel')} aria-hidden="true" className={classes.badgeButton}>
          <ServiceLaneChip
            count={badges.ServiceLane.count}
            ownership={badges.ServiceLane.ownership}
            badgeStyle={{ height: 45, width: 38, marginRight: 16 }}
          />
        </div>
        <div onClick={handleItemClick('protectionProductsPanel')} aria-hidden="true" className={classes.badgeButton}>
          <ProtectionProductChip
            count={badges.ProtectionProducts.count}
            ownership={badges.ProtectionProducts.ownership}
            badgeStyle={{ height: 43, width: 35, marginRight: 16 }}
          />
        </div>
        <div onClick={handleItemClick('unredeemedProductsPanel')} aria-hidden="true" className={classes.badgeButton}>
          <RedemptionsChip
            count={badges.UnredeemedProducts.count}
            ownership={badges.UnredeemedProducts.ownership}
            badgeStyle={{ height: 32, width: 32 }}
          />
        </div>
      </CardActions>
    </Card>
  );
};
