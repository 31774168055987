import * as React from 'react';
import { withTheme } from '@material-ui/core';

function DailyIconComponent(props) {
  return (
    <svg width={25} viewBox="0 0 152.43 139.54" {...props}>
      <g transform="translate(-3186 1016.5)" fill="none">
        <g data-name="Path 140">
          <path d="M3207.768-990.78h108.513a8.968 8.968 0 018.968 8.968v80.713a8.968 8.968 0 01-8.968 8.968h-108.513a8.968 8.968 0 01-8.968-8.968v-80.713a8.968 8.968 0 018.968-8.968z" />
          <path d="M3207.768-986.632a4.826 4.826 0 00-4.82 4.82v80.716a4.826 4.826 0 004.82 4.82h108.513a4.826 4.826 0 004.82-4.82v-80.716a4.826 4.826 0 00-4.82-4.82h-108.513m0-4.148h108.513a8.968 8.968 0 018.968 8.968v80.715a8.968 8.968 0 01-8.968 8.968h-108.513a8.968 8.968 0 01-8.968-8.968v-80.715a8.968 8.968 0 018.968-8.968z" />
        </g>
        <g stroke={props.theme.palette.primary.contrastIcon}>
          <path d="M3198.8-958.68h122.87" strokeWidth={9.146} data-name="Path 141" />
          <path d="M3228.1-1008.3v31.415" strokeLinecap="round" strokeWidth={13.956} data-name="Path 138" />
          <path d="M3291.4-1008.3v31.415" strokeLinecap="round" strokeWidth={13.956} data-name="Path 139" />
          <path d="M3239.2-925.39l14.541 20.744s15.854-27.436 34.237-33.357" strokeWidth={7.631} data-name="Path 137" />
        </g>
        <rect
          x={3197.2}
          y={-989.51}
          width={127.95}
          height={103.89}
          ry={10.346}
          stroke={props.theme.palette.primary.contrastIcon}
          strokeWidth={9.835}
        />
      </g>
    </svg>
  );
}

export const DailyIcon = withTheme(DailyIconComponent);
