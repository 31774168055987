/* eslint-disable no-restricted-globals */
import { isNullOrWhitespace } from './isNullOrWhitespace';

export const toGridNumber = (n) => {
  if (isNullOrWhitespace(n)) return null;

  const number = Number(n);

  if (isNaN(number)) return null;

  return number;
};
