const defaultMinHeight = 40;

export function gridAutosizeHeaders(event) {
  event.api.setHeaderHeight(defaultMinHeight);

  const headerCells = document.querySelectorAll('.ag-header-cell-label');

  let rowHeight = defaultMinHeight;

  // find max column title height
  headerCells.forEach((cell) => {
    rowHeight = Math.max(rowHeight, cell.scrollHeight);
  });

  // allow only to resize up to 3 times bigger otherwise UI can get messed up
  rowHeight = rowHeight > defaultMinHeight * 3 ? defaultMinHeight * 3 : rowHeight;

  // set header row hight to match max title height
  event.api.setHeaderHeight(rowHeight + 16);
}
