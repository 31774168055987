import React from 'react';
import { makeStyles, IconButton, Tooltip, Grid, Typography } from '@material-ui/core';

import { Save as SaveIcon, SettingsBackupRestore as SettingsBackupRestoreIcon } from '@material-ui/icons';
import { SearchSelectField } from '../atoms';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: 3,
  },
  rightAlign: {
    textAlign: 'right',
  },
  centerAlign: {
    textAlign: 'center',
  },
  titleTypography: {
    fontFamily: 'roboto, sans-serif',
    textAlign: 'center',
    fontSize: '0.7rem',
    marginLeft: 20,
    marginTop: 14,
  },
  iconButton: {
    marginRight: -10,
    marginTop: 4,
  },
  iconSave: {
    marginRight: 1,
    marginTop: 4,
  },
  tooltip: {
    width: '100%',
  },
  itemContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    padding: 1,
  },
}));

function HeaderGrid(props) {
  const classes = useStyles();
  const {
    text,
    pageSize,
    onInputChange,
    pageSizeOptions,
    restoreGridSettings,
    saveGridSettings,
    isEnabledRestoreIcon,
    isEnabledSaveIcon,
  } = props;
  return (
    <div className={classes.header}>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={9}>
          <Typography component="h2" variant="overline" className={classes.titleTypography}>
            {text}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.rightAlign}>
          <SearchSelectField
            label="Page Size:"
            name="pageSize"
            onChange={onInputChange}
            value={pageSize}
            options={pageSizeOptions}
            secondStyle
          />
        </Grid>
        <Grid item xs={1} className={classes.centerAlign}>
          <IconButton
            className={classes.iconButton}
            edge="end"
            color="inherit"
            aria-label="mode"
            onClick={restoreGridSettings}
            disabled={!isEnabledRestoreIcon}
          >
            <Tooltip className={classes.tooltip} title="Restore Default Settings" arrow>
              <div className={classes.itemContainer}>
                <SettingsBackupRestoreIcon className={classes.headerIcons} />
              </div>
            </Tooltip>
          </IconButton>
          <IconButton
            className={classes.iconSave}
            edge="end"
            color="inherit"
            aria-label="mode"
            onClick={saveGridSettings}
            disabled={!isEnabledSaveIcon}
          >
            <Tooltip className={classes.tooltip} title="Save Settings" arrow>
              <div className={classes.itemContainer}>
                <SaveIcon className={classes.headerIcons} />
              </div>
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export { HeaderGrid };
