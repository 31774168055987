import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { NamesCellRenderer, TextCellRenderer } from '../atoms';
import { GlobalState } from '../../GlobalState';
import { displayDatetimeFormatter } from '../../lib/operators/displayDatetimeFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { gridTextToNumberComparator } from '../../lib/operators/gridTextToNumberComparator';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
  },
});

class LeadSearchGridTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.asaPermissions = GlobalState.PermissionsMediator.getAsaPermissions();
    this.setupStatics();
  }

  componentDidMount() {
    this.dataGrid.api.sizeColumnsToFit();

    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  onRowSelection = (evt) => {
    const selected = evt.api.getSelectedRows();
    const leadId = selected[0].lead_identity_id;
    this.props.onLeadSelection(leadId);
  };

  setupStatics = () => {
    this.frameworkComponents = {
      namesCellRenderer: NamesCellRenderer,
      textRenderer: TextCellRenderer,
    };

    this.onFirstDataRendered = (params) => {
      params.api.sizeColumnsToFit();
    };

    this.columnDefinitions = [
      {
        headerName: 'ID',
        field: 'lead_identity_id',
        sortable: true,
        width: 60,
        cellRenderer: 'textRenderer',
        headerTooltip: 'ID',
        resizable: true,
      },
      {
        headerName: 'Lead',
        field: 'full_name',
        cellRenderer: 'textRenderer',
        filter: true,
        sortable: true,
        width: 170,
        headerTooltip: 'Lead',
        resizable: true,
      },
      {
        headerName: 'City',
        field: 'city',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 95,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.city);
        },
        headerTooltip: 'City',
        resizable: true,
      },
      {
        headerName: 'State',
        field: 'state',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 95,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.state);
        },
        headerTooltip: 'State',
        resizable: true,
      },
      {
        headerName: 'ASA',
        field: 'asa',
        sortable: true,
        width: 95,
        filter: 'agTextColumnFilter',
        cellRenderer: 'textRenderer',
        filterParams: { comparator: gridTextToNumberComparator },
        cellStyle: (params) => {
          if (this.asaPermissions.includes(params.value)) {
            return { color: this.props.theme.hendrick.asaIndicators.currentAsaColor };
          }
          return null;
        },
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.asa);
        },
        headerTooltip: 'ASA',
        resizable: true,
      },
      {
        headerName: 'Phone',
        field: 'phones',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        valueGetter: (params) => {
          return params.data.phones && params.data.phones[0] ? params.data.phones[0] : '-';
        },
        width: 105,
        headerTooltip: 'Phone',
        resizable: true,
      },
      {
        headerName: 'Email',
        field: 'emails',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        valueGetter: (params) => {
          return params.data.emails && params.data.emails[0] ? params.data.emails[0] : '-';
        },
        width: 130,
        headerTooltip: 'Email',
        resizable: true,
      },
      {
        headerName: 'Date of lead',
        field: 'last_activity_datetime',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => displayDatetimeFormatter(value),
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        width: 110,
        comparator: gridDatetimeSortComparator,
        headerTooltip: 'Date of lead',
        resizable: true,
      },
      {
        headerName: 'Last activity',
        field: 'last_activity_description',
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        width: 120,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.last_activity_description);
        },
        headerTooltip: 'Last activity',
        resizable: true,
      },
    ];
  };

  render() {
    const { classes, data } = this.props;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.paper}>
            <div style={{ height: 520, width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              {data && (
                <AgGridReact
                  ref={(c) => (this.dataGrid = c)}
                  paginationAutoPageSize
                  rowData={data}
                  columnDefs={this.columnDefinitions}
                  content={{ componentParent: this }}
                  rowSelection="single"
                  frameworkComponents={this.frameworkComponents}
                  pagination
                  rowHeight={38}
                  headerHeight={40}
                  onFirstDataRendered={this.onFirstDataRendered}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const LeadSearchGridTemplate = withStyles(styles)(withTheme(LeadSearchGridTemplateComponent));
