/* eslint-disable no-restricted-globals */
export const parseDatetimeForView = (d) => {
  if (d === null || d === undefined) return null;

  const output = new Date(d);

  if (isNaN(output)) {
    return null;
  }

  return output;
};
