import React from 'react';
import { TextField, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlSecond: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
}));
const SearchSelectField = (props) => {
  const classes = useStyles();
  const { label, name, value, options, onChange, secondStyle } = props;
  return (
    <TextField
      className={secondStyle ? classes.formControlSecond : classes.formControl}
      select
      id={`standard-select select${name}`}
      variant="outlined"
      size="small"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export { SearchSelectField };
