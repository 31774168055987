import * as React from 'react';
import { withTheme } from '@material-ui/core';

function LogoWithFlagComponent(props) {
  return (
    <svg viewBox="0 0 384 165" {...props}>
      <defs>
        <path
          id="prefix__a"
          d="M229.85.01l-23.47 61.31h-5.24c-1.17 0-3.28-.86-4.33-2.57-2.94-4.82-.73-10.04 2.98-13.83 2.25-2.29 4.76-3.66 8.19-4.37 1.22-.25 2.51-.23 3.48-.2.78-2.02 7.01-18.22 7.79-20.25-3.46-.18-7.1-.25-9.96.1-11.83 1.45-21.95 8.77-27.24 19.31-5.14 10.19-9.52 22.32-3.75 32.99 1.46 2.76 5.33 7.01 10.69 7.01h30.28L247.81.01h-17.96"
        />
        <path
          id="prefix__b"
          d="M370.56 20.06c-5.07 11.32-9.32 15.44-22.43 15.77.91-2.39 5.45-14.33 13.62-35.82h-15.5l-29.48 79.5h15.58c5.65-15.53 8.78-24.15 9.41-25.88 7.5-.31 10.28 7.17 8.39 12.45-.32.89-1.9 5.37-4.76 13.43h16.48c1.6-4.52 2.49-7.03 2.66-7.54 1.34-4.08 2.76-8.95 3.12-12.21.62-5.21-.09-10.76-2.98-15.25 3.85-1.81 7.29-4.52 10.04-7.78 3.32-3.87 6.41-8.39 7.58-13.11.05-.24.27-1.43.68-3.56h-12.41"
        />
        <path
          id="prefix__c"
          d="M96.15 61.32c-2.67 0-4.64-2.64-5.16-4.96-1.01-4.6 0-9.67 2.31-13.14 2.9-4.37 7.8-7.59 13.28-7.59h9.26L95.51 59.05l16.12-.03 20.2-23.31 5.75-15.65c-17.05.01-26.53.02-28.42.03-10.15.02-19.72 4.6-26.31 12.27-7.06 8.2-9.73 22.68-9.28 28.62.61 7.93 4.7 18.53 14.32 18.53h27.89l6.91-18.19H96.15"
        />
        <path
          id="prefix__d"
          d="M170.19 20.2l-28.82-.14-22 59.45h15.38l16.29-43.87h7.46c2.42 0 3.89 2.51 3.04 4.7-1.01 2.61-6.08 15.67-15.19 39.17h17.67c9.46-24.46 14.71-38.06 15.76-40.77 3-7.8-.06-18.54-9.59-18.54"
        />
        <path id="prefix__e" d="M71.57.01L59.43 34.47H37.52l-7.45 19.46h22.35l-19.65 52.68 18.51-.04L90.35.01H71.57" />
        <path
          id="prefix__f"
          d="M308.45 61.32c-2.93-.02-6.25-2.37-6.25-5.37 0-2.51 2.17-8.59 3.99-10.77 2.17-2.62 6.33-5.18 9.95-5.18h10.96l7.88-19.94c-5.76-.01-8.97-.01-9.61-.01-7.56 0-15.89 2.02-22.04 6.49-9.15 6.65-14.04 13.03-17.14 22.13-2.36 7.02-4.32 15.53-1.89 22.52 1.63 4.68 6.39 8.32 11.47 8.32h16.75l6.79-18.19h-10.86"
        />
        <path
          id="prefix__g"
          d="M265.37 40l6.96-19.94h-3.68c-4.61 0-13.2 1.32-17.07 3.23-7.24 3.61-11.91 8.15-14.99 16.72-.99 2.63-5.96 15.8-14.9 39.5h17.86c7.14-19.58 11.11-30.46 11.9-32.64 1.44-3.95 5.2-6.4 9.28-6.81.31-.01 1.86-.03 4.64-.06"
        />
        <path id="prefix__h" d="M31.76.01L1.03 79.51h16.71L48.16.01h-16.4" />
        <path id="prefix__i" d="M273.83 25.79l-19.11 53.72h17.75l19.39-53.72h-18.03" />
        <path id="prefix__j" d="M301.37.01h-18.22l-7.22 19.91h18.06L301.37.01" />
        <path
          id="prefix__k"
          d="M277.01 124.14l-260.91 2.1 1.73 1.47 280.68 3.45 6.55-17.02-299.52 3.14 1.79 1.52 271.32 1.06-1.64 4.28zm-10.78 28.1l-1.6 4.15-212.44.49 1.81 1.54L286.3 163l6.51-16.96-252.05 1.13 1.79 1.52 223.68 3.55zm4.04-10.52l2.52-6.58-244.06 1.83 1.82 1.54 239.72 3.21zM308.68 163l6.51-16.96h24.13L332.81 163h-24.13zm36.35-31.83H320.9l6.55-17.06h24.13l-6.55 17.06zm-29.84 14.87l5.71-14.87h-22.39l-5.7 14.87h22.38"
        />
        <path
          id="prefix__l"
          d="M78.89 101.34H89.8c2.63 0 3.93-.75 4.96-3.6.1-.27.89-2.44.99-2.71 1.04-2.85.29-3.6-2.35-3.6H82.49l-3.6 9.91zm-.74-14.89h18.56c6.15 0 7.58 2.02 5.77 6.98-.22.6-1.99 5.48-2.21 6.08-1.81 4.96-4.7 6.98-10.85 6.98H70.86l7.29-20.04"
        />
        <path
          id="prefix__m"
          d="M121.5 99.51l-6.35-13.06h7.42l3.78 8.19 9.74-8.19h7.42l-15.86 13.06-2.54 6.98h-6.15l2.54-6.98"
        />
        <path
          id="prefix__n"
          d="M154.08 86.45h9.53l6.25 14.56h.05l5.3-14.56h6.15l-7.29 20.04h-9.53l-6.25-14.56h-.06l-5.3 14.56h-6.15l7.3-20.04"
        />
        <path
          id="prefix__o"
          d="M198.38 98.63h7.87L205 91.1l-6.62 7.53zm4.32-12.18h8.01l3.73 20.04h-6.84l-.63-3.54h-12.46l-3.09 3.54h-6.87l18.15-20.04"
        />
        <path
          id="prefix__p"
          d="M232.21 86.45h9.45l2.26 12.9 11.64-12.9h9.45l-7.29 20.04h-5.99l5.24-14.39h-.06l-13.29 14.39h-4.6L236.2 92.1h-.06l-5.24 14.39h-5.98l7.29-20.04"
        />
        <path id="prefix__q" d="M277.79 86.45h6.15l-7.29 20.04h-6.15l7.29-20.04" />
        <path
          id="prefix__r"
          d="M293.73 93.48c2.13-5.87 5.93-7.25 11.47-7.25h7.23c8.75 0 9.2 1.88 7.08 7.55h-6.15c.12-.33.19-.51.21-.55.52-1.44-.38-1.85-2.21-1.85h-6.7c-3.24 0-4.41 1.52-5.31 4.01l-.79 2.16c-1.06 2.91-.85 4.01 2.06 4.01h6.56c2.77 0 3.7-.27 4.61-2.76h6.15c-.41 1.12-.64 1.75-.69 1.88-1.22 3.37-3.41 6.03-9.59 6.03h-9.91c-5.54 0-8.33-1.38-6.2-7.25.15-.4.87-2.39 2.18-5.98"
        />
        <path
          id="prefix__s"
          d="M329.18 99.93h6.14c-.74 2.13.05 2.3 1.83 2.3h7.53c1.3 0 2.68-.14 3.25-1.69.63-1.74-.05-1.85-7.97-1.85-9.25 0-9.9-1.11-8.11-6.04 1.32-3.63 2.67-6.42 10.43-6.42h8.58c7.09 0 6.88 2.41 5.6 5.92-.01.04-.09.26-.23.64h-6.15c.54-1.47.59-2.08-2.23-2.08h-6.82c-1.38 0-2.49.47-2.89 1.55-.63 1.75.1 1.8 6.7 1.77 9.56-.02 11.57.56 9.44 6.4-1.96 5.4-4.11 6.28-12.28 6.28h-7.45c-5.6 0-7.46-1.05-5.88-5.4.04-.09.2-.55.51-1.38"
        />
        <path
          id="prefix__t"
          d="M345.57 149.79c.32 0 .58-.29.58-.62 0-.32-.26-.57-.63-.57h-1.16v1.17c.65.01 1.05.02 1.21.02zm-2.04-1.77h2.09c.74 0 1.2.48 1.2 1.12 0 .54-.33.94-.8 1.03.29.06.56.25.65.71.12.41-.01.81.08 1.22H346c-.06-.12-.07-.17-.07-.7 0-.63-.38-.92-.74-.92h-.83v1.62h-.83v-4.08m1.56-1.05c-1.67 0-3.02 1.37-3.02 3.03 0 1.69 1.35 3.03 3.03 3.03a3.03 3.03 0 003.04-3.03c0-1.66-1.37-3.03-3.05-3.03zm-3.65 3.03c0-2.02 1.61-3.62 3.64-3.62 1.95 0 3.58 1.64 3.58 3.61 0 1.99-1.65 3.61-3.6 3.61-2.01 0-3.62-1.64-3.62-3.6"
        />
      </defs>
      <use fill="#ed1c24" xlinkHref="#prefix__a" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__a" />
      <g fill="#ed1c24">
        <use xlinkHref="#prefix__b" />
        <use strokeOpacity={0} xlinkHref="#prefix__b" />
      </g>
      <use fill="#ed1c24" xlinkHref="#prefix__c" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__c" />
      <g fill="#ed1c24">
        <use xlinkHref="#prefix__d" />
        <use strokeOpacity={0} xlinkHref="#prefix__d" />
      </g>
      <g fill="#ed1c24">
        <use xlinkHref="#prefix__e" />
        <use strokeOpacity={0} xlinkHref="#prefix__e" />
      </g>
      <use fill="#ed1c24" xlinkHref="#prefix__f" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__f" />
      <use fill="#ed1c24" xlinkHref="#prefix__g" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__g" />
      <use fill="#ed1c24" xlinkHref="#prefix__h" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__h" />
      <use fill="#ed1c24" xlinkHref="#prefix__i" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__i" />
      <use fill="#ed1c24" xlinkHref="#prefix__j" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__j" />
      <g fill={props.theme.palette.primary.contrastText}>
        <use xlinkHref="#prefix__k" />
        <use strokeOpacity={0} xlinkHref="#prefix__k" />
        <use xlinkHref="#prefix__l" />
        <use strokeOpacity={0} xlinkHref="#prefix__l" />
        <use xlinkHref="#prefix__m" />
        <use strokeOpacity={0} xlinkHref="#prefix__m" />
        <use xlinkHref="#prefix__n" />
        <use strokeOpacity={0} xlinkHref="#prefix__n" />
        <use xlinkHref="#prefix__o" />
        <use strokeOpacity={0} xlinkHref="#prefix__o" />
        <use xlinkHref="#prefix__p" />
        <use strokeOpacity={0} xlinkHref="#prefix__p" />
        <use xlinkHref="#prefix__q" />
        <use strokeOpacity={0} xlinkHref="#prefix__q" />
        <use xlinkHref="#prefix__r" />
        <use strokeOpacity={0} xlinkHref="#prefix__r" />
        <use xlinkHref="#prefix__s" />
        <use strokeOpacity={0} xlinkHref="#prefix__s" />
      </g>
      <use fill={props.theme.palette.primary.contrastText} xlinkHref="#prefix__t" />
      <use fillOpacity={0} strokeOpacity={0} xlinkHref="#prefix__t" />
    </svg>
  );
}

export const LogoWithFlag = withTheme(LogoWithFlagComponent);
