import React from 'react';
import { RedemptionsBadgeGreyed, RedemptionsBadgeOther, RedemptionsBadgeOwn } from '../atoms';
import { ChipBase } from './ChipBase';

const pickBadge = (unusedRedemtionsCount, ownership) => {
  if (unusedRedemtionsCount > 0 && ownership === 'MINE') return <RedemptionsBadgeOwn />;
  if (unusedRedemtionsCount > 0 && ownership === 'OTHER') return <RedemptionsBadgeOther />;

  return <RedemptionsBadgeGreyed />;
};

const RedemptionsChip = (props) => {
  const component = pickBadge(props.count, props.ownership);
  return ChipBase(props, component);
};

export { RedemptionsChip };
