import React, { PureComponent } from 'react';
import { withSnackbar } from 'notistack';
import { Paper, Grid } from '@material-ui/core';
import { PageLayout, RankingViewTemplate } from '../template';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { API } from '../../lib/xhr';
import { PageTitle, EmptyDataPlaceholder } from '../molecules';
import { DailyIcon, WeeklyIcon, MonthlyIcon, SearchSelectField, SearchMultiField, SearchButton } from '../atoms';
import { GlobalState } from '../../GlobalState';

class RankingByDatePageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.notifier = new SnackbarVisitor(this.props);
    this.api = new API(this.notifier);

    this.dealershipsStorage = GlobalState.PermissionsMediator.getDealerships();
    this.asaPermissionsStorage = GlobalState.PermissionsMediator.getAsaPermissions();

    this.urlSearchParams = new URLSearchParams(this.props.location.search);

    this.urlVehicleType = this.urlSearchParams.get('vehicleType');
    this.urlLeadType = this.urlSearchParams.get('leadType');
    this.urlDealershipType = this.urlSearchParams.get('dealershipType');

    this.vehicleTypeOptions = [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: 'NEW',
        label: 'New',
      },
      {
        value: 'USED',
        label: 'Used',
      },
      {
        value: 'UNSPECIFIED',
        label: 'Unspecified',
      },
    ];

    this.leadTypeOptions = [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: 'MY_DEALERSHIP_VIPS',
        label: 'My dealership VIPS',
      },
      {
        value: 'HENDRICK_VIPS',
        label: 'Hendrick VIPS',
      },
      {
        value: 'PROSPECT',
        label: 'Prospect',
      },
    ];

    this.state = {
      dateLabel: this.props.match.params.dateLabel,
      vehicleType: this.urlVehicleType || this.vehicleTypeOptions[0].value,
      leadType: this.urlLeadType || this.leadTypeOptions[0].value,
      dealershipType: this.dealershipsStorage,
      rankingData: [],
      dealerships: [],
    };
  }

  componentDidMount() {
    const existDealerships = false;
    this.getRanking(existDealerships);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateLabel !== this.state.dateLabel) {
      const existDealerships = true;
      this.getRanking(existDealerships);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.dateLabel !== state.dateLabel) {
      return {
        dateLabel: props.match.params.dateLabel,
      };
    }
    return null;
  }

  getFilterDate = () => {
    const now = new Date();
    let filterDate = now;

    if (this.state.dateLabel === 'week') {
      filterDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
    }
    if (this.state.dateLabel === 'month') {
      filterDate = new Date(now.getFullYear(), now.getMonth(), 1);
    }

    return filterDate.toISOString().slice(0, 10);
  };

  getRanking = (existDealerships) => {
    if (!existDealerships) {
      this.api.request
        .get(`dealership`)
        .success((res) => this.onInputChangeDifferentAll(res.body))
        .go();
    }

    const filters = [
      { key: 'vehicleType', label: this.state.vehicleType },
      { key: 'leadType', label: this.state.leadType },
    ];

    const body = [];
    const asa = this.asaPermissionsStorage;

    const dealers = this.state.dealershipType.length === 0 ? this.dealershipsStorage : this.state.dealershipType;

    dealers.forEach((ds) => {
      body.push({ code: ds.code ? ds.code : ds.id, name: ds.name, asa });
    });

    const searchObj = Object.assign({}, ...filters.map((x) => ({ [x.key]: x.label })));

    let searchParams = new URLSearchParams(searchObj).toString();
    searchParams = searchParams ? `?${searchParams}` : '';

    this.props.history.push(`/ranking/${this.state.dateLabel}${searchParams}`);

    this.api.request
      .post(`ranking/${this.getFilterDate()}${searchParams}`, body)
      .success((res) => {
        this.setState({ rankingData: res.body });
      })
      .go();
  };

  onGoToLead = (leadId) => {
    this.props.history.push(`/lead/${leadId}`);
  };

  onInputChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onInputChangeDifferentAll = (res) => {
    const newRes = [];
    const { dealershipsStorage } = this;
    const { dealershipType } = this.state;
    const newDealershipsType = dealershipType;
    const nameAll = 'ALL';
    newRes.push({ id: 0, name: nameAll, selected: false });
    res.forEach((element) => {
      element.selected = false;
      if (dealershipsStorage.find((dealer) => dealer.code === element.id)) {
        element.selected = true;
      }
      newRes.push(element);
    });
    this.setState({ dealerships: newRes, dealershipType: newDealershipsType });
  };

  onHandleChangeItem = (data, dataToSend) => {
    this.setState({ dealerships: data, dealershipType: dataToSend });
  };

  onHandleSearch = () => {
    const existDealerships = true;
    if (this.state.dealershipType.length > 0) this.getRanking(existDealerships);
  };

  render() {
    const { rankingData, dateLabel, vehicleType, leadType, dealershipType, dealerships } = this.state;
    const dataIsReady = !!(rankingData && rankingData.length);
    const dateTitle = dateLabel === 'week' ? 'last 7 days' : dateLabel;

    return (
      <PageLayout>
        <Grid container direction="row" justify="space-between">
          <PageTitle title={`Leads of the ${dateTitle}`}>
            {dateLabel === 'day' && <DailyIcon />}
            {dateLabel === 'week' && <WeeklyIcon />}
            {dateLabel === 'month' && <MonthlyIcon />}
          </PageTitle>
        </Grid>

        <div>
          <SearchSelectField
            label="Select vehicle type"
            name="vehicleType"
            onChange={this.onInputChange}
            value={vehicleType}
            options={this.vehicleTypeOptions}
          />
          <SearchSelectField
            label="Select lead type"
            name="leadType"
            onChange={this.onInputChange}
            value={leadType}
            options={this.leadTypeOptions}
          />
          <SearchMultiField
            itemName="store name"
            name="dealershipType"
            onChange={this.onHandleChangeItem}
            onHandleChangeItem={this.onHandleChangeItem}
            value={dealershipType}
            items={dealerships}
            itemsStorage={this.dealershipsStorage}
          />
          <SearchButton onChange={this.onHandleSearch} />
        </div>

        {dataIsReady ? (
          <RankingViewTemplate onLeadSelection={this.onGoToLead} data={rankingData} dateTitle={dateLabel} />
        ) : (
          <Paper>
            <EmptyDataPlaceholder>No data to display for this time period</EmptyDataPlaceholder>
          </Paper>
        )}
      </PageLayout>
    );
  }
}
export const RankingByDatePage = withSnackbar(RankingByDatePageComponent);
