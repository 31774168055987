const DefaultPropensityRankingPreferences = {
  PageName: 'propensity ranking',
  PageSize: '10',
  GridPreferences: [],
};

const DefaultLeadOfTheDayPreferences = {
  PageName: 'leads of the day',
  PageSize: '10',
  GridPreferences: [],
};

const DefaultLeadOfTheWeekPreferences = {
  PageName: 'leads of the week',
  PageSize: '10',
  GridPreferences: [],
};

const DefaultLeadOfTheMonthPreferences = {
  PageName: 'leads of the month',
  PageSize: '10',
  GridPreferences: [],
};

const DefaultUserPreferences = {
  theme_value: 'dark',
  pages: [
    {
      ...DefaultPropensityRankingPreferences,
    },
    {
      ...DefaultLeadOfTheDayPreferences,
    },
    {
      ...DefaultLeadOfTheWeekPreferences,
    },
    {
      ...DefaultLeadOfTheMonthPreferences,
    },
  ],
};

export { DefaultUserPreferences, DefaultPropensityRankingPreferences, DefaultLeadOfTheMonthPreferences };
