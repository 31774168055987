import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { BadgesCellRenderer, NamesCellRenderer, RankingNumberCellRenderer, TextCellRenderer } from '../atoms';
import { GlobalState } from '../../GlobalState';
import { displayDatetimeFormatter } from '../../lib/operators/displayDatetimeFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { gridTextToNumberComparator } from '../../lib/operators/gridTextToNumberComparator';
import { gridBadgeComparator } from '../../lib/operators/gridBadgeComparator';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';
import { HeaderGrid } from '../molecules';
import { DefaultPropensityRankingPreferences } from '../../lib/preferences/DefaultUserPreferences';
import { API } from '../../lib/xhr';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { gridAutosizeHeaders } from '../../lib/operators/gridAutosizeHeaders';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
});

class PropensityRankingTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.notifier = new SnackbarVisitor(props);
    this.api = new API(this.notifier);

    this.asaPermissions = GlobalState.PermissionsMediator.getAsaPermissions();
    this.userPreferencesStorage = GlobalState.PermissionsMediator.getUserPreferences();
    this.propensityRankingStorage = this.userPreferencesStorage.pages.filter(
      (x) => x.PageName === 'propensity ranking'
    );
    this.existPropensityRankingStorage = this.propensityRankingStorage.length > 0;
    this.pageSizeOptions = [
      { label: '10', value: '10' },
      { label: '25', value: '25' },
      { label: '50', value: '50' },
    ];
    this.changePageSize = false;

    const statePageSize = this.existPropensityRankingStorage
      ? this.pageSizeOptions.filter((x) => x.value === this.propensityRankingStorage[0].PageSize)[0].value
      : this.pageSizeOptions[0].value;

    this.state = {
      pageSize: statePageSize,
      isEnabledRestoreIcon: !!(statePageSize !== '10' && this.existPropensityRankingStorage),
      isEnabledSaveIcon: false,
    };

    this.setupStatics();
  }

  componentDidMount() {
    this.dataGrid.api.sizeColumnsToFit();
    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  onRowSelection = (evt) => {
    const selected = evt.api.getSelectedRows();
    const leadId = selected[0].lead_identity_id;
    this.props.onLeadSelection(leadId);
  };

  setupStatics = () => {
    this.frameworkComponents = {
      badgesRenderer: BadgesCellRenderer,
      namesCellRenderer: NamesCellRenderer,
      rankRenderer: RankingNumberCellRenderer,
      textRenderer: TextCellRenderer,
    };

    const column = (headerName, field) => ({ headerName, field, headerTooltip: headerName });

    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
    };
    this.columnDefinitions = [
      {
        ...column('#', 'ranking_position'),
        cellRenderer: 'rankRenderer',
        sortable: true,
        width: 1,
        index: 0,
      },
      {
        ...column('Lead', 'full_name'),
        cellRenderer: 'textRenderer',
        filter: true,
        sortable: true,
        width: 160,
        index: 1,
      },
      {
        ...column('Vehicles', 'sales_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'vehicle',
        sortable: true,
        width: 90,
        index: 2,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Services', 'services_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'serviceLane',
        sortable: true,
        width: 81,
        index: 3,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Protection products', 'warranties_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'protectionProduct',
        sortable: true,
        width: 130,
        index: 4,
        comparator: gridBadgeComparator,
      },
      {
        ...column('Unused redemptions', 'unredeemed_badge'),
        cellRenderer: 'badgesRenderer',
        colId: 'unusedRedemptions',
        sortable: true,
        width: 135,
        index: 5,
        comparator: gridBadgeComparator,
      },
      {
        ...column('ASA', 'asa'),
        sortable: true,
        width: 75,
        index: 6,
        filter: 'agTextColumnFilter',
        cellRenderer: 'textRenderer',
        filterParams: { comparator: gridTextToNumberComparator },
        cellStyle: (params) => {
          if (this.asaPermissions.includes(params.value)) {
            return { color: this.props.theme.hendrick.asaIndicators.currentAsaColor };
          }
          return null;
        },
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.asa);
        },
      },
      {
        ...column('Date of lead', 'last_activity_datetime'),
        sortable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }) => displayDatetimeFormatter(value),
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        width: 117,
        index: 7,
        comparator: gridDatetimeSortComparator,
      },
      {
        ...column('Last activity', 'last_activity_description'),
        filter: true,
        sortable: true,
        cellRenderer: 'textRenderer',
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.last_activity_description);
        },
        width: 150,
        index: 8,
      },
    ];
    this.columnDefinitions.sort((a, b) => {
      return a.index - b.index;
    });
  };

  onInputChange = (event) => {
    this.changePageSize = true;
    this.setState({ [event.target.name]: event.target.value, isEnabledRestoreIcon: true, isEnabledSaveIcon: true });
    this.dataGrid.api.paginationSetPageSize(Number(event.target.value));
  };

  restorePageSettings = () => {
    const pageName = 'propensity ranking';
    const body = { PageName: pageName, ComponentName: 'propensity_ranking_grid' };
    this.api.request
      .post(`userpreferences/restoreGridSettings`, body)
      .success((res) => {
        if (res.body) {
          const getUserPreferences = GlobalState.PermissionsMediator.getUserPreferences();
          const userPreferencesPage = getUserPreferences.pages.filter((x) => x.PageName === pageName);
          userPreferencesPage[0].PageSize = this.pageSizeOptions[0].value;
          GlobalState.PermissionsMediator.setUserPreferences(getUserPreferences);

          this.preferences = DefaultPropensityRankingPreferences;
          this.dataGrid.api.paginationSetPageSize(Number(this.pageSizeOptions[0].value));
          this.setState({
            pageSize: this.pageSizeOptions[0].value,
            isEnabledRestoreIcon: false,
            isEnabledSaveIcon: false,
          });
        }
      })
      .go();
  };

  savePageSettings = () => {
    const body = {
      PageName: 'propensity ranking',
    };

    if (this.changePageSize) {
      body.PageSize = this.state.pageSize;
    }

    this.api.request
      .post(`userpreferences/saveGridPreferences`, body)
      .success((res) => {
        const page = res.body.Pages.filter((x) => x.PageName === 'propensity ranking');

        const getUserPreferences = GlobalState.PermissionsMediator.getUserPreferences();
        const userPreferencesPage = getUserPreferences.pages.filter((x) => x.PageName === 'propensity ranking');
        userPreferencesPage[0].PageSize = page[0].PageSize;
        GlobalState.PermissionsMediator.setUserPreferences(getUserPreferences);

        this.setState({
          pageSize: page[0].PageSize,
          isEnabledRestoreIcon: true,
          isEnabledSaveIcon: false,
        });
      })
      .go();
  };

  render() {
    const { classes, data } = this.props;
    const { pageSize, isEnabledRestoreIcon, isEnabledSaveIcon } = this.state;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.paper}>
            <div style={{ height: '100%', width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              <HeaderGrid
                text="Hendrick Relationships"
                pageSize={pageSize}
                onInputChange={this.onInputChange}
                pageSizeOptions={this.pageSizeOptions}
                restoreGridSettings={this.restorePageSettings}
                saveGridSettings={this.savePageSettings}
                isEnabledRestoreIcon={isEnabledRestoreIcon}
                isEnabledSaveIcon={isEnabledSaveIcon}
              />
              {data && (
                <AgGridReact
                  ref={(c) => (this.dataGrid = c)}
                  paginationPageSize={pageSize}
                  rowData={data}
                  columnDefs={this.columnDefinitions}
                  content={{ componentParent: this }}
                  rowSelection="single"
                  frameworkComponents={this.frameworkComponents}
                  pagination
                  rowHeight={38}
                  groupHeaderHeight={35}
                  suppressHorizontalScroll
                  onFirstDataRendered={this.onFirstDataRendered}
                  onColumnResized={gridAutosizeHeaders}
                  onGridReady={gridAutosizeHeaders}
                  defaultColDef={this.defaultColDef}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const PropensityRankingTemplate = withStyles(styles)(withTheme(PropensityRankingTemplateComponent));
