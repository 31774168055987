import React from 'react';
import { VehicleBadgeRed, VehicleBadgeYellow, VehicleBadgeGreyed } from '../atoms';
import { NoChipBase } from './NoChipBase';

const pickBadge = (dateInStock, type) => {
  if (!dateInStock || !type) return <VehicleBadgeGreyed />;

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const dateInStockSplit = dateInStock.split('-');

  const utc1 = Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  const utc2 = Date.UTC(dateInStockSplit[0], dateInStockSplit[1], dateInStockSplit[2]);

  let utcDiff = utc1 - utc2;
  if (utc1 < utc2) {
    utcDiff = utc2 - utc1;
  }

  const days = Math.floor(utcDiff / _MS_PER_DAY);

  if (type.toLowerCase() === 'new') {
    if (days >= 0 && days <= 199) return <VehicleBadgeGreyed />;
    if (days >= 245 && days <= 335) return <VehicleBadgeYellow />;
    return <VehicleBadgeRed />; // days >= 336
  }
  if (days >= 0 && days <= 59) return <VehicleBadgeYellow />;
  if (days >= 60) return <VehicleBadgeRed />;
  return <VehicleBadgeGreyed />;
};

const AgeInventoryChip = (props) => {
  const component = pickBadge(props.dateInStock, props.type);
  return NoChipBase(props, component);
};
export { AgeInventoryChip };
