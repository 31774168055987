import * as React from 'react';
import { withTheme } from '@material-ui/core';

function MonthlyIconComponent(props) {
  return (
    <svg width={25} viewBox="0 0 152.43 139.54" {...props}>
      <g data-name="Group 163">
        <g fill="none">
          <g data-name="Path 140">
            <path d="M16.394 20.27h122a10.083 10.806 0 0110.084 10.805v97.25a10.083 10.806 0 01-10.083 10.806H16.394A10.083 10.806 0 016.31 128.326V31.075a10.083 10.806 0 0110.083-10.806z" />
            <path d="M16.394 25.267c-2.989 0-5.42 2.606-5.42 5.808v97.254c0 3.202 2.431 5.808 5.42 5.808h122c2.99 0 5.42-2.606 5.42-5.808V31.075c0-3.202-2.43-5.808-5.42-5.808h-122m0-4.998h122c5.57 0 10.084 4.838 10.084 10.806v97.254c0 5.968-4.515 10.805-10.083 10.805H16.394c-5.57 0-10.083-4.838-10.083-10.805V31.075c0-5.968 4.514-10.806 10.083-10.806z" />
          </g>
          <g stroke={props.theme.palette.primary.contrastIcon}>
            <path
              d="M3199.8-962.47h122.87"
              strokeWidth={6}
              data-name="Path 141"
              transform="matrix(1.1243 0 0 1.2049 -3590.1 1214.06)"
            />
            <path
              d="M3228.2-1003.5v25.856"
              strokeLinecap="round"
              strokeWidth={11.599}
              data-name="Path 138"
              transform="matrix(1.1243 0 0 1.2049 -3590.1 1214.06)"
            />
            <path
              d="M3292.2-1003.5v25.856"
              strokeLinecap="round"
              strokeWidth={11.599}
              data-name="Path 139"
              transform="matrix(1.1243 0 0 1.2049 -3590.1 1214.06)"
            />
            <path
              d="M3207.8-986.63h104a9.311 9.311 0 019.332 9.332v75.04a9.311 9.311 0 01-9.332 9.332h-104a9.311 9.311 0 01-9.332-9.331v-75.041a9.311 9.311 0 019.332-9.332z"
              strokeWidth={8.338}
              transform="matrix(1.1243 0 0 1.2049 -3590.1 1214.06)"
            />
          </g>
        </g>
        <g
          transform="matrix(1.1141 0 0 1.0043 20.5 62.24)"
          clipPath="url(#prefix__clip-path)"
          data-name="Repeat Grid 16"
        >
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M-2.055 7.14h14.717v14.938H-2.055z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 -2.055 7.14)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M18.845 7.14h14.717v14.938H18.845z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 18.845 7.14)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M39.645 7.14h14.717v14.938H39.645z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 39.645 7.14)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M60.545 7.14h14.717v14.938H60.545z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 60.545 7.14)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M81.345 7.14h14.717v14.938H81.345z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 81.345 7.14)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M-2.055 28.3h14.717v14.938H-2.055z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 -2.055 28.3)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M18.845 28.3h14.717v14.938H18.845z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 18.845 28.3)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M39.645 28.3h14.717v14.938H39.645z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 39.645 28.3)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M60.545 28.3h14.717v14.938H60.545z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 60.545 28.3)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M81.345 28.3h14.717v14.938H81.345z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 81.345 28.3)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M-2.055 49.47h14.717v14.938H-2.055z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 -2.055 49.47)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M18.845 49.47h14.717v14.938H18.845z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 18.845 49.47)" />
          </g>
          <g fill="none" stroke={props.theme.palette.primary.contrastIcon} strokeWidth={2} data-name="Rectangle 46">
            <path stroke="none" d="M39.645 49.47h14.717v14.938H39.645z" />
            <path d="M1 1h10v10H1z" transform="matrix(1.2264 0 0 1.2448 39.645 49.47)" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export const MonthlyIcon = withTheme(MonthlyIconComponent);
