import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  searchButton: {
    marginLeft: 9,
    marginTop: 10,
  },
}));

const SearchButton = (props) => {
  const classes = useStyles();
  const { onChange } = props;
  return (
    <Button color="secondary" variant="outlined" className={classes.searchButton} onClick={onChange}>
      Search
    </Button>
  );
};
export { SearchButton };
